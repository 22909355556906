import type React from 'react';
import {
  Badge,
  Box,
  Column,
  Columns,
  Hidden,
  IconExperience,
  IconInfo,
  IconMinus,
  IconRecommended,
  IconRocket,
  IconTick,
  type Inline,
  Stack,
  Strong,
  Text,
  TooltipRenderer,
} from 'braid-design-system';
import { vars } from 'braid-design-system/css';

import * as styles from './JobAdFeatureChart.css';

const TableRow = ({
  embed,
  children,
}: {
  embed?: boolean;
  children: React.ReactNode;
}) => (
  <Box
    style={{
      color: embed
        ? vars.foregroundColor.brandAccentLight
        : vars.backgroundColor.neutralLight,
    }}
    className={styles.tableRow}
  >
    {children}
  </Box>
);

const TableCellBox = ({
  alignY,
  align,
  embed,
  children,
}: {
  alignY?: React.ComponentProps<typeof Inline>['alignY'];
  align?: React.ComponentProps<typeof Inline>['align'];
  embed?: boolean;
  children: React.ReactNode;
}) => (
  <Box
    padding={{ mobile: 'xsmall', desktop: 'small' }}
    display="flex"
    alignItems={alignY === 'top' ? 'flexStart' : 'center'}
    justifyContent={align === 'left' ? 'flexStart' : 'center'}
    style={{
      color: embed
        ? vars.foregroundColor.brandAccentLight
        : vars.backgroundColor.neutralLight,
    }}
    className={styles.tableCell}
  >
    {children}
  </Box>
);

const Pink = ({ children }: { children: React.ReactNode }) => (
  <span style={{ color: vars.foregroundColor.brandAccent }}>{children}</span>
);

const ResponsiveText = ({
  size,
  children,
  tone,
}: {
  size: {
    mobile: React.ComponentProps<typeof Text>['size'];
    desktop: React.ComponentProps<typeof Text>['size'];
  };
  tone?: React.ComponentProps<typeof Text>['tone'];
  children: string;
}) => (
  <>
    <Hidden below="desktop">
      <Text size={size.desktop} align="left" tone={tone ?? 'neutral'}>
        {children}
      </Text>
    </Hidden>
    <Hidden above="tablet">
      <Text size={size.mobile} align="left" tone={tone ?? 'neutral'}>
        {children}
      </Text>
    </Hidden>
  </>
);

export const JobAdFeatureChart = ({ embed }: { embed?: boolean }) => (
  <Box
    paddingY={embed ? 'small' : 'medium'}
    paddingX={embed ? 'xxsmall' : { mobile: 'xsmall', desktop: 'medium' }}
    background={embed ? 'brandAccentSoft' : undefined}
    borderRadius={embed ? 'xlarge' : 'standard'}
  >
    <Stack space="none">
      <TableRow embed={embed}>
        {/* Header row */}
        <TableCellBox embed={embed}>
          <Box />
        </TableCellBox>
        <TableCellBox alignY="top" embed={embed}>
          <Stack space={{ mobile: 'xsmall', desktop: 'medium' }}>
            <Columns
              space="xxsmall"
              align="center"
              alignY="center"
              collapseBelow="desktop"
              reverse
            >
              <Column width="content">
                <IconRecommended tone="brandAccent" />
              </Column>
              <Column width="content">
                <Hidden below="desktop">
                  <Text size="large" weight="medium">
                    <Pink>Basic</Pink>
                  </Text>
                </Hidden>
                <Hidden above="tablet">
                  <Text weight="medium">
                    <Pink>Basic</Pink>
                  </Text>
                </Hidden>
              </Column>
            </Columns>
            <Hidden below="desktop">
              <Text size="xsmall">
                Offers a balance between performance and cost with exposure to
                relevant candidates - ideal for{' '}
                <Strong>easy-to-fill roles</Strong>
              </Text>
            </Hidden>
            <Hidden above="tablet">
              <Box width="full" justifyContent={'center'} display="flex">
                <TooltipRenderer
                  id="info-basic"
                  tooltip={
                    <Text size="xsmall">
                      Offers a balance between performance and cost with
                      exposure to relevant candidates - ideal for easy-to-fill
                      roles
                    </Text>
                  }
                >
                  {({ triggerProps }) => (
                    <Box aria-label="Info" {...triggerProps}>
                      <IconInfo tone="info" />
                    </Box>
                  )}
                </TooltipRenderer>
              </Box>
            </Hidden>
          </Stack>
        </TableCellBox>
        <TableCellBox alignY="top" embed={embed}>
          <Stack space={{ mobile: 'xsmall', desktop: 'medium' }}>
            <Columns
              space="xxsmall"
              align="center"
              alignY="center"
              collapseBelow="desktop"
              reverse
            >
              <Column width="content">
                <IconRocket tone="brandAccent" />
              </Column>
              <Column width="content">
                <Hidden below="desktop">
                  <Text size="large" weight="medium">
                    <Pink>Advanced</Pink>
                  </Text>
                </Hidden>
                <Hidden above="tablet">
                  <Text weight="medium">
                    <Pink>Advanced</Pink>
                  </Text>
                </Hidden>
              </Column>
            </Columns>
            <Hidden below="desktop">
              <Text size="xsmall">
                Increased exposure and enhanced targeting - for roles that
                require <Strong>specific skills</Strong> or have tighter{' '}
                <Strong>competition for talent</Strong>
              </Text>
            </Hidden>
            <Hidden above="tablet">
              <Box width="full" justifyContent={'center'} display="flex">
                <TooltipRenderer
                  id="info-advanced"
                  tooltip={
                    <Text size="xsmall">
                      Increased exposure and enhanced targeting - for roles that
                      require specific skills or have tighter competition for
                      talent.
                    </Text>
                  }
                >
                  {({ triggerProps }) => (
                    <Box aria-label="Info" {...triggerProps}>
                      <IconInfo tone="info" />
                    </Box>
                  )}
                </TooltipRenderer>
              </Box>
            </Hidden>
          </Stack>
        </TableCellBox>
        <TableCellBox alignY="top" embed={embed}>
          <Stack space={{ mobile: 'xsmall', desktop: 'medium' }}>
            <Columns
              space="xxsmall"
              align="center"
              alignY="center"
              collapseBelow="desktop"
              reverse
            >
              <Column width="content">
                <IconExperience tone="brandAccent" />
              </Column>
              <Column width="content">
                <Hidden below="desktop">
                  <Text size="large" weight="medium">
                    <Pink>Premium</Pink>
                  </Text>
                </Hidden>
                <Hidden above="tablet">
                  <Text weight="medium">
                    <Pink>Premium</Pink>
                  </Text>
                </Hidden>
              </Column>
            </Columns>
            <Hidden below="desktop">
              <Text size="xsmall">
                Our top-performing ad, with maximum exposure and features to
                help you stand out - designed for{' '}
                <Strong>hard-to-fill, critical and urgent roles</Strong>
              </Text>
            </Hidden>
            <Hidden above="tablet">
              <Box width="full" justifyContent={'center'} display="flex">
                <TooltipRenderer
                  id="info-premium"
                  tooltip={
                    <Text size="xsmall">
                      Our top-performing ad, with maximum exposure and features
                      to help you stand out - designed for hard-to-fill,
                      critical and urgent roles.
                    </Text>
                  }
                >
                  {({ triggerProps }) => (
                    <Box aria-label="Info" {...triggerProps}>
                      <IconInfo tone="info" />
                    </Box>
                  )}
                </TooltipRenderer>
              </Box>
            </Hidden>
          </Stack>
        </TableCellBox>
      </TableRow>
      {/* 2nd row */}
      <TableRow embed={embed}>
        <TableCellBox align="left" embed={embed}>
          <ResponsiveText size={{ mobile: 'xsmall', desktop: 'small' }}>
            Exposure in search and recommendations
          </ResponsiveText>
        </TableCellBox>
        <TableCellBox embed={embed}>
          <Badge weight={embed ? 'strong' : 'regular'}>Standard</Badge>
        </TableCellBox>
        <TableCellBox embed={embed}>
          <Badge weight={embed ? 'strong' : 'regular'}>Promoted</Badge>
        </TableCellBox>
        <TableCellBox embed={embed}>
          <Badge weight={embed ? 'strong' : 'regular'}>Prioritised</Badge>
        </TableCellBox>
      </TableRow>
      {/* 3rd row */}
      <TableRow embed={embed}>
        <TableCellBox align="left" embed={embed}>
          <ResponsiveText size={{ mobile: 'xsmall', desktop: 'small' }}>
            Candidate invitations
          </ResponsiveText>
        </TableCellBox>
        <TableCellBox embed={embed}>
          <Badge weight={embed ? 'strong' : 'regular'}>20</Badge>
        </TableCellBox>
        <TableCellBox embed={embed}>
          <Badge weight={embed ? 'strong' : 'regular'}>30</Badge>
        </TableCellBox>
        <TableCellBox embed={embed}>
          <Badge weight={embed ? 'strong' : 'regular'}>80</Badge>
        </TableCellBox>
      </TableRow>
      {/* 4th row */}
      <TableRow embed={embed}>
        <TableCellBox align="left" embed={embed}>
          <ResponsiveText size={{ mobile: 'xsmall', desktop: 'small' }}>
            Enhanced targetting
          </ResponsiveText>
        </TableCellBox>
        <TableCellBox embed={embed}>
          <IconMinus tone="secondary" />
        </TableCellBox>
        <TableCellBox embed={embed}>
          <IconTick tone="brandAccent" />
        </TableCellBox>
        <TableCellBox embed={embed}>
          <IconTick tone="brandAccent" />
        </TableCellBox>
      </TableRow>
      {/* 5th row */}
      <TableRow embed={embed}>
        <TableCellBox align="left" embed={embed}>
          <ResponsiveText size={{ mobile: 'xsmall', desktop: 'small' }}>
            Promoted on competitive ads
          </ResponsiveText>
        </TableCellBox>
        <TableCellBox embed={embed}>
          <IconMinus tone="secondary" />
        </TableCellBox>
        <TableCellBox embed={embed}>
          <IconTick tone="brandAccent" />
        </TableCellBox>
        <TableCellBox embed={embed}>
          <IconTick tone="brandAccent" />
        </TableCellBox>
      </TableRow>
      {/* 6th row */}
      <TableRow embed={embed}>
        <TableCellBox align="left" embed={embed}>
          <ResponsiveText size={{ mobile: 'xsmall', desktop: 'small' }}>
            Maintain exclusivity on your ad
          </ResponsiveText>
        </TableCellBox>
        <TableCellBox embed={embed}>
          <IconMinus tone="secondary" />
        </TableCellBox>
        <TableCellBox embed={embed}>
          <IconMinus tone="secondary" />
        </TableCellBox>
        <TableCellBox embed={embed}>
          <IconTick tone="brandAccent" />
        </TableCellBox>
      </TableRow>
      {/* 7th row */}
      <TableRow embed={embed}>
        <TableCellBox align="left" embed={embed}>
          <ResponsiveText size={{ mobile: 'xsmall', desktop: 'small' }}>
            Your ad sent exclusively to high-fit candidates
          </ResponsiveText>
        </TableCellBox>
        <TableCellBox embed={embed}>
          <IconMinus tone="secondary" />
        </TableCellBox>
        <TableCellBox embed={embed}>
          <IconMinus tone="secondary" />
        </TableCellBox>
        <TableCellBox embed={embed}>
          <IconTick tone="brandAccent" />
        </TableCellBox>
      </TableRow>
      {/* 8th row */}
      <TableRow embed={embed}>
        <TableCellBox align="left" embed={embed}>
          <ResponsiveText size={{ mobile: 'xsmall', desktop: 'small' }}>
            Branding
          </ResponsiveText>
        </TableCellBox>
        <TableCellBox embed={embed}>
          <ResponsiveText
            size={{ mobile: 'xsmall', desktop: 'small' }}
            tone="secondary"
          >
            Optional
          </ResponsiveText>
        </TableCellBox>
        <TableCellBox embed={embed}>
          <ResponsiveText
            size={{ mobile: 'xsmall', desktop: 'small' }}
            tone="secondary"
          >
            Optional
          </ResponsiveText>
        </TableCellBox>
        <TableCellBox embed={embed}>
          <IconTick tone="brandAccent" />
        </TableCellBox>
      </TableRow>
    </Stack>
  </Box>
);
